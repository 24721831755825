import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, map, mergeMap, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Category } from '../model/category.model';
import { DfUnit } from '../model/df-unit.model';
import { CategoryService } from './category.service';

@Injectable({
  providedIn: 'root',
})
export class DfService {
  constructor(private http: HttpClient, private categoryService: CategoryService) {}

  getReal(numDays: number, category?: Category): Observable<{ data_n_1: DfUnit; data_n: DfUnit }[]> {
    return combineLatest([this.categoryService.getCategories(), this.categoryService.getCurrentMeta()]).pipe(
      mergeMap((result) => {
        let [categories, meta] = result;

        let params: { [key: string]: string } = {};
        if (numDays) {
          params['day'] = '' + numDays;
        }

        params['meta_category'] = meta.id + '';

        if (category && category.id) {
          params['category'] = category.id + '';
          //@todo not working,
          return this.http
            .get<{ data_n_1: DfUnit[]; data_n: DfUnit[] }>(`${environment.backendUrl}/api/real/`, { params })
            .pipe(
              map((result) => {
                let units: { data_n_1: DfUnit; data_n: DfUnit }[] = [];
                result.data_n.forEach((dfu, key) => {
                  units.push({ data_n: dfu, data_n_1: result.data_n_1[key] });
                });
                return units;
              }),
            );
        }
        //no category asked : returning overall data
        else {
          return this.http
            .get<{ data_n_1: DfUnit[]; data_n: DfUnit[] }>(`${environment.backendUrl}/api/allreal/`, { params })
            .pipe(
              map((result) => {
                let units: { data_n_1: DfUnit; data_n: DfUnit }[] = [];
                result.data_n.forEach((dfu, key) => {
                  units.push({ data_n: dfu, data_n_1: result.data_n_1[key] });
                });
                return units;
              }),
            );
        }
      }),
    );
  }

  getDfTo(
    numDays: number,
    category?: Category,
  ): Observable<{ category: number; pourcent_dispo?: number; stock?: number }[]> {
    return combineLatest([this.categoryService.getCategories(), this.categoryService.getCurrentMeta()]).pipe(
      mergeMap((result) => {
        let [categories, meta] = result;

        let params: { [key: string]: string } = {};
        if (numDays) {
          params['day'] = '' + numDays;
        }
        if (category) {
          params['category'] = '' + category.id;
        }

        params['meta_category'] = meta.id + '';

        return this.http
          .get<{ [key: string]: [{ stock?: number; pourcent_dispo?: number }] }>(`${environment.backendUrl}/api/to/`, {
            params: params,
          })
          .pipe(
            map((data: { [key: string]: [{ pourcent_dispo?: number; stock?: number }] }) => {
              let arr: { category: number; pourcent_dispo?: number; stock?: number }[] = [];
              for (const [key, value] of Object.entries(data)) {
                if (value[0].pourcent_dispo) {
                  arr.push({ category: parseInt(key), pourcent_dispo: value[0].pourcent_dispo });
                } else {
                  arr.push({ category: parseInt(key), stock: value[0].stock });
                }
              }
              return arr;
            }),
          );
      }),
    );
  }
}
