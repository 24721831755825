import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CampaignSegment } from '../model/campaign-segment.model';
import { Category } from '../model/category.model';
import { CategoryService } from './category.service';
import 'anychart';

interface Segment {
  categoryIds: Category['id'][];
  numb_mail: number;
  pourcentBase: number;
  pourcentVente: number;
  proba: number;
  list_id?: number[];
}

export interface PostCampagneResponse {
  segments: Segment[];
  numb_all_mail: number;
  venn: {
    x: string[];
    value: number;
  }[];
}

export interface Campagne {
  segments: Segment[];
  numb_all_mail: number;
  venn: {
    id: string[];
    x: string[];
    value: number;
  }[];
}

@Injectable({
  providedIn: 'root',
})
export class CampaignService {
  constructor(private http: HttpClient, private categoryService: CategoryService) {}

  getLiftCurve(category: Category): Observable<{ optimal: number; curve: { x: number; y: number }[] } | undefined> {
    return this.http
      .get<{ [key: string]: { ideal_pourcent_base: number; curve: number[] } }>(
        `${environment.backendUrl}/api/campagnecurve/`,
        {
          params: {
            category: category.id ?? 0,
            meta_category: this.categoryService.currentMeta?.id ?? 0,
          },
        },
      )
      .pipe(
        map((response) => {
          let v0Key = `${category.id}`;
          let values: { ideal_pourcent_base: number; curve: number[] } | undefined = undefined;
          let vk = Object.entries(response);
          for (const [k, v] of vk) {
            if (k === v0Key && typeof v === 'object') {
              values = v;
            }
          }

          if (values) {
            return {
              optimal: values.ideal_pourcent_base,
              curve: values.curve.map((value, index) => ({ x: index + 1, y: value })),
            };
          } else {
            throw new Error();
          }
        }),
      );
  }

  mergeCampaigns(capping: number, segments: CampaignSegment[]): Observable<Campagne> {
    return this.http
      .post<PostCampagneResponse>(`${environment.backendUrl}/api/campagne/`, {
        meta_category: this.categoryService.currentMeta?.id ?? 0,
        send: false,
        capping,
        segments: segments.reduce(
          (
            res: { lists: { [key: number]: { [key: number]: number } }; indexOfMerge: number },
            segment: CampaignSegment,
            index: number,
          ) => {
            const percentage = segment.categories.reduce(
              (res, category) => ({ ...res, [category.category.id]: category.percentage }),
              {},
            );
            if (segment.checked) {
              const pos = res.indexOfMerge || index + 1;
              return {
                lists: {
                  ...res.lists,
                  [pos]: {
                    ...res.lists[pos],
                    ...percentage,
                  },
                },
                indexOfMerge: pos,
              };
            } else {
              const pos = index + 1 - (res.indexOfMerge ? Object.keys(res.lists[res.indexOfMerge]).length - 1 : 0);
              return {
                ...res,
                lists: {
                  ...res.lists,
                  [pos]: percentage,
                },
              };
            }
          },
          {
            lists: {},
            indexOfMerge: 0,
          },
        ).lists,
      })
      .pipe(
        map((res: PostCampagneResponse) => ({
          ...res,
          venn: res.venn.map((item: { x: string[]; value: number }) => ({
            id: item.x,
            x: item.x.map((x: string) =>
              x
                .split(',')
                .map((id) => id.trim())
                .map((id) => this.categoryService.getCategoryById(parseInt(id))?.label || '')
                .join(','),
            ),
            value: item.value,
          })),
        })),
      );
  }

  generateSegmentsText(capping: number, segments: CampaignSegment[]): Observable<string> {
    return this.http.post(
      `${environment.backendUrl}/api/campagne/`,
      {
        meta_category: this.categoryService.currentMeta?.id ?? 0,
        send: true,
        capping,
        segments: segments.reduce(
          (res: { [key: number]: { [key: number]: number } }, segment: CampaignSegment, index: number) => {
            const percentage = segment.categories.reduce(
              (res, category) => ({ ...res, [category.category.id]: category.percentage }),
              {},
            );
            return {
              ...res,
              [index + 1]: percentage,
            };
          },
          {},
        ),
      },
      {
        observe: 'body',
        responseType: 'text',
      },
    );
  }
  generateSegmentsBlob(capping: number, segments: CampaignSegment[]): Observable<Blob> {
    return this.http.post(
      `${environment.backendUrl}/api/campagne/`,
      {
        meta_category: this.categoryService.currentMeta?.id ?? 0,
        send: true,
        capping,
        segments: segments.reduce(
          (res: { [key: number]: { [key: number]: number } }, segment: CampaignSegment, index: number) => {
            const percentage = segment.categories.reduce(
              (res, category) => ({ ...res, [category.category.id]: category.percentage }),
              {},
            );
            return {
              ...res,
              [index + 1]: percentage,
            };
          },
          {},
        ),
      },
      {
        observe: 'body',
        responseType: 'blob',
      },
    );
  }
}
