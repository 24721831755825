import { Component, NgZone, OnInit, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CampaignSegment } from 'src/app/deebr/model/campaign-segment.model';
import { Category } from 'src/app/deebr/model/category.model';
import { CategoryService } from 'src/app/deebr/service/category.service';
import { DicoEntry } from 'src/app/deebr/model/dico-entry.model';
import { Campagne, CampaignService } from 'src/app/deebr/service/campaign.service';
import { ChartData, ChartOptions } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { BaseChartDirective } from 'ng2-charts';
import { DicoService } from 'src/app/deebr/service/dico.service';
import { BehaviorSubject, combineLatest, finalize, map, Observable, of, switchMap } from 'rxjs';
import { format } from 'date-fns';
import { MailsService } from 'src/app/deebr/service/mails.service';
import { MetaCategory } from 'src/app/deebr/model/meta-category.model';
import { DfService } from 'src/app/deebr/service/df.service';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { EntrepriseService } from 'src/app/deebr/service/entreprise.service';
import { generateForecastDates } from 'src/app/deebr/model/chart';
import { StockOrDispo } from '../../table/table.component';
import { DownloadService } from 'src/app/deebr/service/download.service';
import { ChangeDetectorRef } from '@angular/core';



@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.scss'],
})

export class CampaignComponent implements OnInit {
  form!: FormGroup;
  segmentCreationForm!: FormGroup;
  segments: CampaignSegment[] = [];
  selectedSegment: CampaignSegment | null = null;
  categories: Category[] = [];
  currentCategory: Category | undefined = undefined;
  capping = 1;
  optimal = 30;
  volume = 30;
  numberOfDays = 7;
  emailsRatio: number | undefined = undefined;
  mailsCount: number = 0;
  dicoEntries: DicoEntry[] = [];
  stockOrDispo: StockOrDispo = StockOrDispo.Stock;
  displayForms = false;
  currentCategoryData: DicoEntry | undefined = undefined;
  forecastDates: Date[] = [];
  @ViewChild('dialog') dialog!: TemplateRef<any>;
  @ViewChild('dialogError') dialogError!: TemplateRef<any>;
  @ViewChild('dialogOk') dialogOk!: TemplateRef<any>;
  dialogRef: NbDialogRef<any> | undefined = undefined;

  selectedCap: number = 1;
  segmentsGenerating = false;
  mergingCampaign = false;
  buildingCampaign = false;
  currentLiftCurveData: { x: number; y: number }[] = [];
  currentLiftCurveFn: Record<number, number> = {};
  potential = 0;
  lift = 0;
  @ViewChildren(BaseChartDirective) charts!: BaseChartDirective[];
  vennDiagrammGenerating = false;
  filteredCategories$!: Observable<Category[]>;
  metaCategory!: MetaCategory;
  metaCategoryChanges = false;
  metaCategories!: MetaCategory[];
  dataRefresh = new BehaviorSubject(false);
  vennData: { x: string[]; id: string[]; value: number }[] | undefined = undefined;
  @ViewChild('instance', { static: true }) instance!: NgbTypeahead;
  allSegmentChecked: boolean = false;
  total: number = 0;
  searchString = '';

  evolData: ChartData<'line', { date: Date; purchase: number }[]> = {
    datasets: [],
  };

  evolCurveChartOptions: ChartOptions<'line'> = {
    elements: {
      line: {
        tension: 0.5,
      },
    },
    parsing: {
      xAxisKey: 'date',
      yAxisKey: 'purchase',
    },
    scales: {
      yAxes: {},
      xAxes: {
        grid: { display: false },
        type: 'time',
        time: {
          unit: 'day',
        },
        ticks: {
          callback: function (value: any, eee: any, aaa: any) {
            return new Date(aaa[eee].value).toLocaleDateString('fr-FR', { day: 'numeric', month: 'short' });
          },
        },
      },
    },

    interaction: {
      intersect: false,
      mode: 'index',
    },

    plugins: {
      legend: { display: true },
      tooltip: {
        displayColors: false,
        callbacks: {
          title: (ctx: any) => {
            let raw: { [key: string]: any } | any = ctx[0].raw;
            if (raw.date) {
              return new Date(raw.date).toLocaleDateString('fr-FR', { day: 'numeric', month: 'short' });
            }
            return '';
          },
          label: (ctx: any) => {
            return (
              (ctx.datasetIndex === 0 ? 'Année N : ' : 'Année N-1 : ') +
              Math.round(parseFloat(ctx.parsed.y)) +
              ' ventes'
            );
          },
        },
      },
    },
  };

  liftCurveChartOptions: ChartOptions<'line'> = {
    responsive: true,

    elements: {
      line: {
        tension: 0.5,
      },
    },
    parsing: {
      xAxisKey: 'x',
      yAxisKey: 'y',
    },
    scales: {
      xAxes: {
        min: 1,
        type: 'linear',
        ticks: {
          callback: (value: string | number) => (typeof value === 'number' ? value : parseFloat(value) * 100) + ' %',
        },
        title: { text: 'volume emails', display: true },
      },
      yAxes: {
        min: 0,
        type: 'linear',
        title: { text: 'Lift', display: true },
      },
    },
  };

  liftCurveData: ChartData<'line', { x: number; y: number }[]> = {
    datasets: [],
  };

  view: 'table' | 'treemap' = 'table';

  get canUpsertSegment(): boolean {
    const canInsert = this.segments.every((segment) =>
      segment.categories.every((category) => this.currentCategory?.id !== category.category.id),
    );
    const canUpdate = !!this.selectedSegment && this.selectedSegment.categories[0].percentage !== this.volume;
    return canInsert || canUpdate;
  }

  get canMergeCampaigns(): boolean {
    return this.segments.filter((segment) => segment.checked).length >= 2;
  }

  constructor(
    private categoryService: CategoryService,
    private dicoService: DicoService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private campaignService: CampaignService,
    private ngZone: NgZone,
    private mailsService: MailsService,
    private dfService: DfService,
    private dialogService: NbDialogService,
    private entrepriseService: EntrepriseService,
    private readonly downloadService: DownloadService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    
    this.metaCategories = this.categoryService.metaCategories;
    if (this.categoryService.currentMeta) {
      this.metaCategory = this.categoryService.currentMeta;
    }
    this.form = this.formBuilder.group({
      capping: [1]  // valeur par défaut
    });
    this.onMetaCategoryChange();
    

    this.segmentCreationForm = this.formBuilder.group({
      metaCategory: new FormControl(this.metaCategory.id),
      volume: new FormControl(this.volume),
      capping: new FormControl(this.capping),
      search: new FormControl(),
    });

    this.segmentCreationForm.get('capping')?.valueChanges.subscribe((value: string) => {
      this.capping = parseInt(value);
      this.buildCampaign();
    });

    this.segmentCreationForm.get('metaCategory')?.valueChanges.subscribe((meta) => {
      this.categoryService.setCurrentMeta(meta);
      //init graphs
      this.onMetaCategoryChange();
    });

    /**
     * when range slider moves, some data needs to be refreshed
     */
    this.segmentCreationForm.get('volume')?.valueChanges.subscribe((volume) => {
      this.volume = volume;

      // volume = % base
      // potential = % vente
      // lift = % vente / % base = potential / volume
      this.emailsRatio = (this.mailsCount * volume) / 100;
      this.lift = this.currentLiftCurveFn[this.volume];
      this.potential = this.lift * volume;
      this.updateLiftPointInitialVolumePosition();
    });

    this.segmentCreationForm.get('search')?.valueChanges.subscribe((searchString) => {
      this.searchString = searchString;
    });

    this.mailsService.getCount().subscribe((count) => {
      this.mailsCount = count;
      this.emailsRatio = (this.mailsCount / this.volume) * 100;
    });

    this.forecastDates = generateForecastDates();
  }
  get cappingControl(): FormControl {
    return this.form.get('capping') as FormControl;
  }
    

  setCategory(categoryId: Category['id']) {
    if (categoryId !== this.currentCategory?.id) {
      this.selectedSegment = null;
      this.setCurrentCategory(`${categoryId}`);
    }
  }

  upsertSegment() {
    if (this.currentCategory) {
      if (!this.selectedSegment) {
        // add
        const potential = this.currentLiftCurveFn[this.volume] * this.volume;
        const mailCount = (this.volume * this.mailsCount) / 100;
        const lift = this.currentLiftCurveFn[this.volume];
        this.selectedSegment = {
          categories: [
            {
              category: this.currentCategory,
              percentage: this.volume,
              potential,
              mailCount,
              lift,
            },
          ],
          percentage: this.volume,
          checked: false,
          potential,
          mailCount,
          lift,
          mailList: [],
        };
        this.segments = [...this.segments, this.selectedSegment];
        this.allSegmentChecked = false;
      } else {
        // update
        this.selectedSegment.percentage = this.volume;
        this.selectedSegment.potential = this.currentLiftCurveFn[this.volume] * this.volume;
        this.selectedSegment.mailCount = (this.volume * this.mailsCount) / 100;
        this.selectedSegment.lift = this.currentLiftCurveFn[this.volume];
        this.selectedSegment.categories[0].percentage = this.selectedSegment.percentage;
        this.selectedSegment.categories[0].potential = this.selectedSegment.potential;
        this.selectedSegment.categories[0].mailCount = this.selectedSegment.mailCount;
        this.selectedSegment.categories[0].lift = this.selectedSegment.lift;
      }

      this.updateLiftPointCurrentVolumePosition();
      this.buildCampaign();
    }
  }

  selectSegment(segment: CampaignSegment): void {
    if (this.selectedSegment !== segment && segment.categories.length === 1) {
      this.selectedSegment = segment;
      if (segment.categories[0].category.id === this.currentCategory?.id) {
        this.segmentCreationForm.get('volume')?.setValue(segment.percentage);
      } else {
        this.setCurrentCategory(`${segment.categories[0].category.id}`, segment.percentage);
      }
    }
  }

  checkAllSegment(checked: boolean): void {
    this.allSegmentChecked = checked;
    this.segments.forEach((segment) => {
      segment.checked = checked;
    });
  }

  checkSegment(segment: CampaignSegment, checked: boolean): void {
    segment.checked = checked;
    this.toggleAllSegmentsChecked();
  }
  onValueChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    this.selectedCap = Number(selectElement.value);
}
  deleteSegment(segment: CampaignSegment) {
    if (this.selectedSegment === segment) {
      this.selectedSegment = null;
    }
    this.segments = this.segments.filter((s) => s !== segment);
    this.toggleAllSegmentsChecked();
    this.buildCampaign();
  }

  askGenerateSegments() {
    this.dialogRef = this.dialogService.open(this.dialog);
  }

  generateSegments() {
    if (this.segments.length > 0) {
        this.segmentsGenerating = true;
        this.dialogRef?.close();
        
        this.entrepriseService.getEntreprise().pipe(
            switchMap((entreprise) => {
                const downloadType = entreprise.results[0].download_segment;
                let expectedResponseType: 'text' | 'blob' = 'text';
                let segmentObservable: Observable<any>; // Pour stocker le bon observable
                
                if (downloadType === 'zip') {
                    expectedResponseType = 'blob';
                    segmentObservable = this.campaignService.generateSegmentsBlob(this.capping, this.segments);
                } else {
                    segmentObservable = this.campaignService.generateSegmentsText(this.capping, this.segments);
                }
                
                return segmentObservable.pipe(
                    map((res) => ({
                        data: res,
                        name: entreprise.results[0].name,
                        downloadType: downloadType,
                    })),
                );
            }),
            finalize(() => {
                this.segmentsGenerating = false;
            }),
        )
        .subscribe({
            next: ({ data, name, downloadType }) => {
                if (downloadType === 'csv') {
                    this.downloadService.saveAsCsv(data, `segments-${name}-${format(new Date(), 'ddMMyyyy')}.csv`);
                } else if (downloadType === 'zip') {
                    const url = window.URL.createObjectURL(data);
                    const anchor = document.createElement('a');
                    anchor.href = url;
                    anchor.download = `segments-${name}-${format(new Date(), 'ddMMyyyy')}.zip`;
                    document.body.appendChild(anchor); 
                    anchor.click();
                    document.body.removeChild(anchor);
                    window.URL.revokeObjectURL(url);  // Nettoyage.
                }
                this.dialogService.open(this.dialogOk);
            },
            error: () => {
                this.dialogService.open(this.dialogError);
            },
        });
    }
}


  mergeCampaigns(): void {
    this.mergingCampaign = true;
    this.vennDiagrammGenerating = true;
    this.campaignService
      .mergeCampaigns(this.capping, this.segments)
      .pipe(
        finalize(() => {
          this.mergingCampaign = false;
          this.vennDiagrammGenerating = false;
        }),
      )
      .subscribe((res: Campagne) => {
        this.allSegmentChecked = false;
        this.selectedSegment = null;
        this.segments = res.segments.map((data) => ({
          categories: data.categoryIds.map((id) => {
            const segment = this.segments.find((segment) =>
              segment.categories.find((category) => category.category.id === id),
            );
            const category = segment?.categories.find((category) => category.category.id === id)!;
            return {
              category: category.category,
              percentage: category.percentage,
            };
          }),
          percentage: data.pourcentBase,
          checked: false,
          potential: data.pourcentVente,
          mailCount: data.numb_mail,
          mailList: [],
          lift: data.pourcentVente / data.pourcentBase,
        }));
        this.total = res.numb_all_mail;
        this.vennData = res.venn;
      });
  }

  private onMetaCategoryChange() {
    this.metaCategoryChanges = true;
    combineLatest([this.dicoService.getDico(this.numberOfDays), this.dfService.getDfTo(this.numberOfDays)]).subscribe(
      (result) => {
        let [entries, dfTo] = result;

        this.segmentCreationForm.patchValue({ category: undefined }, { emitEvent: false });
        this.dicoEntries = entries;

        this.dataRefresh.next(true);

        dfTo.forEach((entry) => {
          this.dicoEntries.forEach((dicoEntry) => {
            if (dicoEntry.category.id === entry.category) {
              if (entry.stock) {
                this.stockOrDispo = StockOrDispo.Stock;
                dicoEntry.stock = entry.stock;
              }
              if (entry.pourcent_dispo) {
                this.stockOrDispo = StockOrDispo.Dispo;
                dicoEntry.dispo = entry.pourcent_dispo * 100;
              }
            }
          });
        });

        //getting categories data
        this.categoryService.getCategories().subscribe((categories) => {
          this.categories = categories;

          //search field init
          this.filteredCategories$ = of(this.categories);

          let catId = this.route.snapshot.paramMap.get('category');
          if (catId && !isNaN(parseInt(catId))) {
            this.setCurrentCategory(catId);
          }
        });
        this.metaCategoryChanges = false;
      },
    );
  }

  updateTrackStyle(): void {
    const customRangeInput = document.querySelector('.custom-range') as HTMLElement;
    const adjustedOptimal = this.optimal * 2;
    customRangeInput.style.setProperty('--optimal-break', `${adjustedOptimal}%`);
}
  
  private setCurrentCategory(categoryId: string, volume?: number) {
    // ngZone usefull for treemap click
    this.ngZone.run(() => {
      this.categories.forEach((category) => {
        if (category.id !== undefined && categoryId !== undefined) {
          if (parseInt(category.id + '') === parseInt(categoryId)) {
            this.currentCategory = category;
          }
        }
      });

      if (this.currentCategory) {
        //hiding forms & charts
        this.displayForms = false;
        this.currentCategoryData = undefined;

        //getting all needed data from backend
        combineLatest([this.campaignService.getLiftCurve(this.currentCategory)]).subscribe((res) => {
          let [lift] = res;

          let data = this.dicoEntries.find((dico: DicoEntry) => dico.category.id === this.currentCategory!.id);

          //lift curve
          this.currentLiftCurveData = lift?.curve.length ? lift.curve : [];
          this.currentLiftCurveFn = this.currentLiftCurveData.reduce(
            (data, value) => ({ ...data, [value.x]: value.y }),
            {},
          );
          this.optimal = lift?.optimal || 30;
          this.updateTrackStyle();
          this.volume = volume || this.optimal;
          this.segmentCreationForm.get('volume')?.setValue(this.volume);
          this.currentCategoryData = data;

          if (data) {
            //previous year tendency dataset
            let previous = data?.curveNMinusOne.map((value, key) => ({
              date: this.forecastDates[key],
              purchase: value,
            }));

            //current year forecast
            let current = data?.evol.map((value, key) => ({
              date: this.forecastDates[key],
              purchase: value,
            }));

            this.evolData = {
              datasets: [
                { data: current, label: 'Année N' },
                { data: previous, label: 'Année N-1' },
              ],
            };

            this.updateLiftCurve();

            //now we can show charts & forms
            this.displayForms = true;
          }
        });
      }
    });
  }

  private updateLiftPointInitialVolumePosition() {
    if (this.liftCurveData?.datasets[0]?.data[0]) {
      this.liftCurveData.datasets[0].data.pop();
      this.liftCurveData.datasets[0].data.push({ x: this.volume, y: this.currentLiftCurveFn[this.volume] });
    }
    this.charts.forEach((elt) => elt.update('none'));
  }

  private updateLiftPointCurrentVolumePosition() {
    if (this.liftCurveData?.datasets[1]?.data[0]) {
      this.liftCurveData.datasets[1].data.pop();
      this.liftCurveData.datasets[1].data.push({ x: this.volume, y: this.currentLiftCurveFn[this.volume] });
    }
    this.charts.forEach((elt) => elt.update('none'));
  }

  private updateLiftCurve() {
    this.liftCurveData.datasets = [
      // initial volume point
      {
        data: [
          {
            x: this.volume,
            y: this.currentLiftCurveFn[this.volume],
          },
        ],
        pointRadius: 8,
        pointBorderColor: 'rgba(255,99,132,1)',
        pointBackgroundColor: 'rgba(255,99,132,1)',
      },

      // current volume point
      {
        data: [
          {
            x: this.selectedSegment?.categories[0].percentage || this.volume,
            y: this.currentLiftCurveFn[this.selectedSegment?.categories[0].percentage || this.volume],
          },
        ],
        pointRadius: 8,
        pointBorderColor: 'rgba(255,99,132,0.5)',
        pointBackgroundColor: 'rgba(255,99,132,0.5)',
      },

      // optimal point
      {
        data: [{ x: this.optimal, y: this.currentLiftCurveFn[this.optimal] }],
        pointRadius: 4,
        pointBorderColor: '#01bcf7',
        pointBackgroundColor: '#01bcf7',
      },

      // blue line
      {
        data: this.currentLiftCurveData,
        pointRadius: 0,
        borderColor: '#01bcf7',
        pointBorderColor: '#01bcf7',
        pointBackgroundColor: '#01bcf7',
      },
    ];

    this.charts.forEach((elt) => elt.update('none'));
  }

  private toggleAllSegmentsChecked(): void {
    this.allSegmentChecked = this.segments.every((segment) => segment.checked);
  }

  private buildCampaign() {
    if (this.segments.length > 0) {
      this.buildingCampaign = true;
      this.vennDiagrammGenerating = true;
      this.campaignService
        .mergeCampaigns(this.capping, this.segments)
        .pipe(
          finalize(() => {
            this.buildingCampaign = false;
            this.vennDiagrammGenerating = false;
          }),
        )
        .subscribe((res: Campagne) => {
          this.segments.forEach((segment) => {
            const data = (res.segments || []).find(
              (data) =>
                segment.categories.map((category) => category.category.id).join(',') === data.categoryIds.join(','),
            );
            if (data) {
              segment.percentage = data.pourcentBase;
              segment.potential = data.pourcentVente;
              segment.mailCount = data.numb_mail;
              segment.lift = data.pourcentVente / data.pourcentBase;
            }
          });
          this.total = res.numb_all_mail;
          this.vennData = res.venn;
        });
    }
  }
}
