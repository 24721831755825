import { Component, OnDestroy, OnInit, ViewChildren } from '@angular/core';
import { DfService } from 'src/app/deebr/service/df.service';
import { ChartConfiguration, Chart, ChartDataset, Scale } from 'chart.js';
import 'chartjs-adapter-date-fns';
import zoomPlugin from 'chartjs-plugin-zoom';
import { Options } from '@angular-slider/ngx-slider';
import { Category } from 'src/app/deebr/model/category.model';
import 'chartjs-chart-treemap';
import { TreemapController, TreemapElement } from 'chartjs-chart-treemap';
import { BaseChartDirective } from 'ng2-charts';
import { DicoService } from 'src/app/deebr/service/dico.service';
import { DicoEntry } from 'src/app/deebr/model/dico-entry.model';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, combineLatest, Observable, tap, catchError, EMPTY, map } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ForecastService } from 'src/app/deebr/service/forecast.service';
import { CategoryService } from 'src/app/deebr/service/category.service';
import { MetaCategory } from 'src/app/deebr/model/meta-category.model';
import { StockOrDispo, TableDataLine } from '../../table/table.component';
import { generateForecastDates, smallSalesChartOptions } from 'src/app/deebr/model/chart';
import { EmailStore, Template } from '../generator/email.store';
import { ViewChild } from '@angular/core';
import { TableComponent } from 'src/app/deebr/component/table/table.component';
import { ProductsService } from 'src/app/deebr/service/product.service';
import { Enterprise, UserStore } from '../../../store/user.store';


let vlineObj: { [key: string]: any } = {
  shouldDraw: true,
};
Chart.register(zoomPlugin);

Chart.register(
  zoomPlugin,
  {  
  id: 'vline',
  afterInit: () => {
    vlineObj['shoulDraw'] = true;
  },
  afterEvent: (chart: any, args: any) => {
    if (args.event.type === 'mouseout') {
      vlineObj['shouldDraw'] = false;
    } else {
      vlineObj['shouldDraw'] = true;
    }
  },
  afterTooltipDraw: (chart: any, args: any) => {
    let tooltip = args.tooltip;
  },

  afterDraw: (chart: any) => {
    if (vlineObj['shouldDraw'] && chart.tooltip?.dataPoints?.length && chart.tooltip?.x !== undefined) {
      let x = chart.tooltip?.dataPoints[0].element.x;
      let yAxis = chart.chartArea;
      let ctx = chart.ctx;
      ctx.save();
      ctx.beginPath();
      ctx.moveTo(x, yAxis.top);
      ctx.lineTo(x, yAxis.bottom);
      ctx.lineWidth = 2;
      ctx.strokeStyle = 'rgba(0, 0, 0, 0.4)';
      ctx.stroke();
      ctx.restore();
    }
  },
});
interface ParsedData {
  x: number;
  y: number;
  _custom: number;
}

//plugin fleche bulle en dehors du cadre :
const chartIdentifiers = {
  bubbleChart: 'bubbleChartId',
  anotherChart: 'anotherChartId'
};

type ArrowDirection = 'left' | 'right' | 'top' | 'bottom';


function drawArrow(ctx: CanvasRenderingContext2D, chart: any, direction: ArrowDirection) {
    
    const opacity = 1

    ctx.fillStyle = "rgb(200, 200, 200)";   // Gris clair pour le remplissage
    let arrowStartX, arrowStartY, arrowHeadA, arrowHeadB;

    switch (direction) {
      case 'left':
          arrowStartX = 80;
          arrowStartY = chart.height / 2;
          arrowHeadA = { x: arrowStartX + 20, y: arrowStartY - 10 };
          arrowHeadB = { x: arrowStartX + 20, y: arrowStartY + 10 };
          break;
      case 'right':
          arrowStartX = chart.width - 80;
          arrowStartY = chart.height / 2;
          arrowHeadA = { x: arrowStartX - 20, y: arrowStartY - 10 }; // Changé à -20
          arrowHeadB = { x: arrowStartX - 20, y: arrowStartY + 10 }; // Changé à -20
          break;
      case 'top':
          arrowStartX = chart.width / 2;
          arrowStartY = 40;
          arrowHeadA = { x: arrowStartX - 10, y: arrowStartY + 20 };  // Changé à +20
          arrowHeadB = { x: arrowStartX + 10, y: arrowStartY + 20 };  // Changé à +20
          break;
      case 'bottom':
          arrowStartX = chart.width / 2;
          arrowStartY = chart.height - 40;
          arrowHeadA = { x: arrowStartX - 10, y: arrowStartY - 20 };  // Changé à -20
          arrowHeadB = { x: arrowStartX + 10, y: arrowStartY - 20 };  // Changé à -20
          break;
  }
  

    ctx.beginPath();
    ctx.moveTo(arrowStartX, arrowStartY);
    ctx.lineTo(arrowHeadA.x, arrowHeadA.y);
    ctx.lineTo(arrowHeadB.x, arrowHeadB.y);
    ctx.closePath();
    ctx.fill();

}

// Utilisation :
// drawArrow(ctx, chart, 'left', animationProgress);



const outOfBoundsPlugin: any = {
  
  id: 'outOfBoundsPlugin',
  afterDraw: function(chart: Chart<'bubble'>) {

    if (chart.canvas?.id !== chartIdentifiers.bubbleChart) {
      return;
    }
    const ctx = chart.ctx;
    let isLeft = false, isRight = false, isTop = false, isBottom = false;
    const xScale = chart.scales['x'];
    const yScale = chart.scales['y'];

    const meta = chart.getDatasetMeta(0);  // Assuming you only have one dataset
    
    meta.data.forEach((element: any, index: number) => {
      const position = element.getCenterPoint();
      const dataPoint: ParsedData = meta._parsed[index] as ParsedData;
      if (dataPoint.x < xScale.min && dataPoint._custom > 0) {
          isLeft = true;
      }
      if (dataPoint.x > xScale.max && dataPoint._custom > 0) {
          isRight = true;
      }
      if (dataPoint.y < yScale.min && dataPoint._custom > 0) {
        isBottom = true; 
      }
      if (dataPoint.y > yScale.max && dataPoint._custom > 0) {
        isTop = true;
      }
  });
  

  if (isLeft) {drawArrow(ctx, chart, 'left')}
  if (isRight) {drawArrow(ctx, chart, 'right')}

  if (isTop) {drawArrow(ctx, chart, 'top')}
  if (isBottom) {drawArrow(ctx, chart, 'bottom')}

  }
};

Chart.register(outOfBoundsPlugin);




@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  private chart?: Chart;
  @ViewChild(TableComponent, { static: false }) tableComponent!: TableComponent;

  public showPerfectstayElement$: Observable<boolean>;
  replaceVenteWithDevis = false;
  salesChartOptions!: ChartConfiguration['options'];
  multi!: any[];
  salesChartData: any;
  dicoEntries: DicoEntry[] = [];
  lines$!: BehaviorSubject<TableDataLine[]> | undefined;
  bestSeller1: DicoEntry | null = null;
  bestSeller2: DicoEntry | null = null;
  categories: Category[] = [];
  value: number = 100;
  numberOfDays = 7;
  dayNumberAndMetaCateforyForm!: FormGroup;
  dataRefresh = new BehaviorSubject(false);
  showPending: boolean = false;
  pendingDayNumber: number = 0;
  metaCategory!: MetaCategory;
  metaCategories!: MetaCategory[];
  dayNumberChanging = false;
  stockOrDispo: StockOrDispo = StockOrDispo.Stock;

  
  forecastDates: Date[] = [];
  sliderOptions: Options = {
    floor: 15,
    ceil: 30,
  };

  globalForecast = 0;
  globalPrevious = 0;
  globalEvol = 0;

  //table tools
  searchForm!: FormGroup;
  searchString = '';

  smallSalesChartOptions: any = smallSalesChartOptions;
  

  afficherTooltip() {
    document.getElementById('tooltipGraphique')!.style.display = 'block';
  }
  
  cacherTooltip() {
    document.getElementById('tooltipGraphique')!.style.display = 'none';
  }
  
  // options for bis sale chart
  private initializeChartOptions() {
    this.salesChartOptions = {
      elements: {
        line: {
          tension: 0.5,
        },
      },
      parsing: {
        xAxisKey: 'date',
        yAxisKey: 'purchase',
      },
      scales: {
        yAxes: {},
        xAxes: {
          grid: { display: false },
          type: 'time',
          time: {
            unit: 'day',
          },
          ticks: {
            callback: function (value: any, eee: any, aaa: any) {
              return new Date(aaa[eee].value).toLocaleDateString('fr-FR', { day: 'numeric', month: 'short' });
            },
          },
        },
      },
      interaction: {
        intersect: false,
        mode: 'index',
      },
      plugins: {
        legend: { display: true },
        tooltip: {
          displayColors: false,
          callbacks: {
            title: (ctx: any) => {
              let raw: { [key: string]: any } | any = ctx[0].raw;
              if (raw.date) {
                return new Date(raw.date).toLocaleDateString('fr-FR', { day: 'numeric', month: 'short' });
              }
              return '';
            },
            label: (ctx: any) => {
              const unit = this.replaceVenteWithDevis ? 'devis' : 'ventes';
              return (
                (ctx.datasetIndex === 0 ? 'Année N : ' : 'Année N-1 : ') +
                Math.round(parseFloat(ctx.parsed.y)) +
                ' ' + unit
              );
            },
          },
        },
      },
    };
  }

  salesChartWorstData: any = {
    datasets: [],
  };

  salesChartBestData: any = {
    datasets: [],
  };

  @ViewChildren(BaseChartDirective) charts!: BaseChartDirective[];

  public templates$: Observable<Template[]> = this.emailStore.selectTemplates();

  constructor(
    private dfService: DfService,
    private forecastService: ForecastService,
    private dicoService: DicoService,
    private formBuilder: FormBuilder,
    private userStore: UserStore,
    private router: Router,
    private route: ActivatedRoute,
    private categoryService: CategoryService,
    private emailStore: EmailStore,
    private readonly productsService: ProductsService) {
      this.showPerfectstayElement$ = this.userStore.selectEnterprise().pipe(
        map((enterprise: Enterprise | null) => {
          if (enterprise?.name.toLowerCase().includes('perfectstay')) {
            this.replaceVenteWithDevis = enterprise.name === 'perfectstay_emirates' || enterprise.name === 'perfectstay_transavia';
            return true;
          }
          return false;
        })
      );
    }

  ngOnInit(): void {
    this.metaCategories = this.categoryService.metaCategories;
    if (this.categoryService.currentMeta) {
      this.metaCategory = this.categoryService.currentMeta;
    }
    this.showPerfectstayElement$.subscribe(() => {
      this.initializeChartOptions();
    });

    this.dayNumberAndMetaCateforyForm = this.formBuilder.group({
      metaCategory: new FormControl(this.metaCategory.id),
      dayNumber: new FormControl(this.numberOfDays),
    });

    this.dayNumberAndMetaCateforyForm.get('metaCategory')?.valueChanges.subscribe((value) => {
      this.categoryService.setCurrentMeta(value);
      this.metaCategory = this.categoryService.currentMeta!;
      this.bestSeller1 = null;
      this.bestSeller2 = null;
      //init graphs
      this.onDayNumberChanged();
    });

    this.dayNumberAndMetaCateforyForm.get('dayNumber')?.valueChanges.subscribe((value) => {
      this.showPending = true;
      this.pendingDayNumber = value;
    });

    this.searchForm = this.formBuilder.group({
      search: new FormControl(),
    });

    // init graphs
    this.forecastDates = generateForecastDates();
    this.onDayNumberChanged();

    //search form init
    this.searchForm.get('search')?.valueChanges.subscribe((searchString) => {
      this.searchString = searchString;
    });
  }
  
  /**
   * Called on number of days form submitted
   */
  numberOfDayFormSubmitted() {
    let newVal = this.dayNumberAndMetaCateforyForm.get('dayNumber')?.value;

    //disable tooltip
    this.showPending = false;

    if (newVal !== this.numberOfDays) {
      this.numberOfDays = newVal;
      this.onDayNumberChanged();
    }
  }

  /**
   * when number of days actually changes (also called on init)
   */
  private onDayNumberChanged() {
    this.dayNumberChanging = true;
    combineLatest([
      this.dicoService.getDico(this.numberOfDays),
      this.dfService.getReal(this.numberOfDays),
      this.forecastService.getForecast(this.numberOfDays),
      this.dfService.getDfTo(this.numberOfDays),
      this.categoryService.getCategories(),
    ]).subscribe((data) => {
      let [dico, real, forecast, dfTo, categories] = data;
      this.dicoEntries = dico;

      // ------------- hub section : backend data converted into "frontend" data ------------
      dfTo.forEach((entry) => {
        this.dicoEntries.forEach((dicoEntry) => {
          if (dicoEntry.category.id === entry.category) {
            if (entry.stock) {
              this.stockOrDispo = StockOrDispo.Stock;
              dicoEntry.stock = entry.stock;
            }
            if (entry.pourcent_dispo) {
              this.stockOrDispo = StockOrDispo.Dispo;
              dicoEntry.dispo = entry.pourcent_dispo * 100;
            }
          }
        });
      });

      let previous = real.map((value, key) => {
        return { date: forecast[key].date, purchase: value.data_n_1.purchase };
      });

      let current = forecast.map((value, key) => {
        return { date: value.date, purchase: value.purchase };
      });

      //-------------------- now data can be provided to all display items ------------------

      this.salesChartData = {
        datasets: [
          { data: current, label: 'Année N' },
          { data: previous, label: 'Année N-1' },
        ],
      };
      this.setLineGraphsData();
      this.makeBubbleChartDataset();
      

      this.globalForecast = current.reduce((prev: number, entry) => {
        return prev + entry.purchase;
    }, 0);
      this.globalPrevious = previous.reduce((prev: number, entry) => {
        return prev + entry.purchase;
    }, 0);
      this.globalEvol = ((this.globalForecast - this.globalPrevious) / this.globalPrevious) * 100;

      window.setTimeout(
        (t: any) => {
          t.dataRefresh.next(true);
        },
        1000,
        this,
      );
      this.dayNumberChanging = false;
      this.dataRefresh.next(true);
    });
  }
  
  /**
   * finds min & max values among all dico entries.
   */
  private setLineGraphsData() {
    // searching for 2 bests, considering only finite values for growth.
    const categoriesSortedByForecast = this.dicoEntries
      .sort((item1, item2) => (item1.forecast < item2.forecast ? 1 : -1));
    this.bestSeller1 = categoriesSortedByForecast[0];
    this.bestSeller2 = categoriesSortedByForecast[1];

    const ratioSeller1 = categoriesSortedByForecast[0]?.forecast;
    const ratioSeller2 = categoriesSortedByForecast[1]?.forecast;

    if (this.bestSeller2?.evol) {
      this.salesChartWorstData = {
        datasets: [
          {
            data: this.bestSeller2.evol.map((evo, key) => ({
              date: this.forecastDates[key].getTime(),
              purchase: evo,
            })),
            borderColor: ratioSeller2 > 0 ? '#74cdcd' : '#F76284',
            pointBorderColor: ratioSeller2 > 0 ? '#74cdcd' : '#F76284',
            pointHoverBorderColor: ratioSeller2 > 0 ? '#74cdcd' : '#F76284',
          },
        ],
      };
    }

    if (this.bestSeller1?.evol) {
      this.salesChartBestData = {
        datasets: [
          {
            data: this.bestSeller1.evol.map((evo, key) => ({
              date: this.forecastDates[key].getTime(),
              purchase: evo,
            })),
            borderColor: ratioSeller1 > 0 ? '#74cdcd' : '#F76284',
            pointBorderColor: ratioSeller1 > 0 ? '#74cdcd' : '#F76284',
            pointHoverBorderColor: ratioSeller1 > 0 ? '#74cdcd' : '#F76284',
          },
        ],
      };
    }
  }


  
  
  bubbleChartTooltipPluginConfig = {
    vline: false,
    legend: { display: false },
    label: { display: true },
    tooltip: {
      titleFontSize: 40,  
      bodyFontSize: 40,    
      footerFontSize: 40,
      callbacks: {
        label: (ctx: any) => {
          const dataIndex = ctx.dataIndex;  // ou ctx.index selon la version et le type de graphique
          const forecast = this.dicoEntries[dataIndex].forecast;
          const unit = this.replaceVenteWithDevis ? 'devis' : 'ventes';

          return [
            'evol vs. N : ' + Math.trunc(ctx.parsed.x * 100) / 100 + ' %',
            'evol vs. N-1 : ' + Math.trunc(ctx.parsed.y * 100) / 100 + ' %',
            'Forecast : ' + Math.trunc(forecast) + ' ' + unit,
          ];
        },
        title: (ctx: any) => {
          if (ctx[0].raw) return ctx[0].raw.data.category.label;
        },
      },
    },
  };
  
  bubbleChartOptions: ChartConfiguration['options'] = {
    
    plugins: {
      zoom: {
        pan: {
          enabled: true,
          mode: 'xy' as const,
        },
        zoom: {
          wheel: {
            enabled: true,
            modifierKey : 'ctrl',
          },
          pinch: {
            enabled: true
          },
          mode: 'xy' as const,
        }
      },
    },
      animation: { 
        easing: "easeOutBounce",
        duration: 2000,
      } 
  };

  private static GRID_CONFIG = {
    lineWidth: (ctx: any) => ctx.tick.value === 0 ? 1 : 0,
    color: (ctx: any) => ctx.tick.value === 0 ? '#aaaaaa' : 'rgba(0,0,0,0)', // '#aaaaaa' est une couleur grise
  };

  private makeBubbleChartOptions(minmaxX: number, minmaxY: number) {
    
    this.bubbleChartOptions = {
      ...this.bubbleChartOptions,
      scales: {
        x: {
          min: -Math.min(Math.ceil((minmaxX * 1.1)/10) * 10, 120),
          max: Math.min(Math.ceil((minmaxX * 1.1)/10) * 10, 120),
          grid: DashboardComponent.GRID_CONFIG,
          title: { text: 'evolution vs ' + this.numberOfDays + ' derniers jours',
                   display: true,
                   font: {
                    size: 16, 
                    weight: 'bold'
                  },
                  padding: {
                    top: 10,
                    bottom: 10
                  }
                  },
          ticks: {
            callback: function(tickValue, index, ticks) {
              if (typeof tickValue === "number") {
                const roundedValue = Math.round(tickValue);
                return roundedValue < 0 ? `${roundedValue}%` : `+${roundedValue}%`;
              }
              return tickValue;  
            }
          }
                  
        },
        y: {
          min: -Math.min(Math.ceil((minmaxX * 1.1)/10) * 10, 120),
          max: Math.min(Math.ceil((minmaxX * 1.1)/10) * 10, 120),
          grid: DashboardComponent.GRID_CONFIG,
          title: { text: 'evolution vs N-1',
                  display: true,
                  font: {
                  size: 16,  // Ajustez la taille selon vos préférences
                  weight: 'bold'  // 'normal' ou 'bold'
                },
                padding: {
                  top: 10,
                  bottom: 10
                }
                },
          ticks: {
            callback: function(tickValue, index, ticks) {
              if (typeof tickValue === "number") {
                const roundedValue = Math.round(tickValue);
                return roundedValue < 0 ? `${roundedValue}%` : `+${roundedValue}%`;
              }
              return tickValue; 
            }
          }
          
        },
      },
      plugins: {
        ...this.bubbleChartOptions?.plugins,
        ...this.bubbleChartTooltipPluginConfig,        
      },
    };
  }

  private bubbleColor(ctx: any) {
    if (!ctx.raw) {
      return '#F76284';
    }
    if (ctx.raw.x > 0 && ctx.raw.y > 0) {
      return '#74cdcd';
    } else if (ctx.raw.x < 0 && ctx.raw.y > 0) {
      return '#ffd877';
    } else if (ctx.raw.x < 0 && ctx.raw.y < 0) {
      return '#ff6384';
    }
    return '#35a2eb';
  }
  private bubbleBackgroundColor = (ctx: any) => {
    let baseColor = this.bubbleColor(ctx);
    baseColor = baseColor.replace('#', '');
    let r = parseInt(baseColor.substring(0, 2), 16);
    let g = parseInt(baseColor.substring(2, 4), 16);
    let b = parseInt(baseColor.substring(4, 6), 16);
    return `rgba(${r},${g},${b},0.5)`;
}
  sellingBubbleChartData: ChartConfiguration['data'] = {
    datasets: [],
  };
  /**
   * Build bubble dataset from dico entries
   */
  private makeBubbleChartDataset() {
    let minForecast = this.dicoEntries.reduce((prev: null | number, current: DicoEntry) => {
      if (!prev) {
        return current.forecast;
      }
      if (prev < current.forecast) {
        return prev;
      }
      return current.forecast;
    }, null);
    let maxForecast = this.dicoEntries.reduce((prev: null | number, current: DicoEntry) => {
      if (!prev) {
        return current.forecast;
      }
      if (prev > current.forecast) {
        return prev;
      }
      return current.forecast;
    }, null);

    let absMaxX = 0;
    let absMaxY = 0;
    let graphEntries = this.dicoEntries.map((entry) => {
      absMaxX =
        isFinite(Math.abs(this.dicoService.getRatio(entry))) && Math.abs(this.dicoService.getRatio(entry)) > absMaxX
          ? Math.abs(this.dicoService.getRatio(entry))
          : absMaxX;
      absMaxY =
        isFinite(Math.abs(this.dicoService.getRatio(entry, true))) &&
        Math.abs(this.dicoService.getRatio(entry, true)) > absMaxY
          ? Math.abs(this.dicoService.getRatio(entry, true))
          : absMaxY;

      return {
        x: this.dicoService.getRatio(entry),
        y: this.dicoService.getRatio(entry, true),
        r: entry.forecast ? Math.log(entry.forecast) * 3 : 15,
        data: entry,
      };
    });
    this.makeBubbleChartOptions(absMaxX, absMaxY);
    
    this.sellingBubbleChartData.datasets = [
      {
        data: graphEntries,
        hoverRadius: 10,
        label: '',
        // legend: false,
        pointHoverBackgroundColor: this.bubbleBackgroundColor,
        pointBackgroundColor: this.bubbleBackgroundColor,
        hoverBackgroundColor: this.bubbleBackgroundColor,
        backgroundColor: this.bubbleBackgroundColor,
        borderColor: this.bubbleColor,
        hoverBorderColor: this.bubbleColor,
      },
    ];
    this.charts.forEach((elt) => elt.update());
  }

  getRatio(dico: DicoEntry | null, lastYear = false) {
    return this.dicoService.getRatio(dico, lastYear);
  }

  ngOnDestroy(): void {
    Chart.unregister(TreemapController, TreemapElement);
  }
  //previousY = (ctx: any) => ctx.index === 0 ? ctx.chart.scales.y.getPixelForValue(100) : ctx.chart.getDatasetMeta(ctx.datasetIndex).data[ctx.index - 1].getProps(['y'], true).y;

  handleTileClick(dicoEntry: DicoEntry) {
    const tableData: TableDataLine = {
        id: dicoEntry.category.id,
        showProducts: false,
        isSegment: false,
    };
    this.tableComponent.toggleProducts(tableData);
    window.scrollTo({ top: 0, behavior: 'smooth' });
}
 
}


