import { Injectable } from '@angular/core';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { map, Observable, switchMap } from 'rxjs';
import { UserService } from '../service/user.service';

export interface User {
  id: number;
  username: string;
  email: string;
  first_name: string;
  last_name: string;
  date_joined: string;
  last_login: string;
  enterprise: Enterprise['id'];
}

export interface Enterprise {
  id: number;
  name: string;
  siret: number;
  is_active: boolean;
  report: boolean;
  email_gen: boolean;
  audience: boolean;
  json_file: string;
  project_id_big_query: string;
  bucket: string;
  db: string;
  mailing_key_public: string;
  mailing_key_private: string;
  mailing_tool: number;
}

export interface UserState {
  user: User | null;
  enterprise: Enterprise | null;
}

const initialState = {
  user: null,
  enterprise: null,
};

@Injectable()
export class UserStore extends ComponentStore<UserState> {
  constructor(private readonly userService: UserService) {
    super(initialState);
  }

  readonly loadUser = this.effect((action$: Observable<void>) => {
    return action$.pipe(
      switchMap(() =>
        this.userService.getUsers().pipe(
          map((users: User[]) => users[0]),
          switchMap((user: User) =>
            this.userService.getEntreprise(user.enterprise).pipe(
              tapResponse(
                (enterprise: Enterprise) => {
                  this.patchState((state) => ({
                    ...state,
                    user,
                    enterprise,
                  }));
                },
                () => {
                  this.patchState((state) => ({
                    ...state,
                  }));
                },
              ),
            ),
          ),
        ),
      ),
    );
  });

  readonly loadEnterprise = this.effect((action$: Observable<void>) => {
    return action$.pipe(
      switchMap(() =>
        this.userService.getEntreprises().pipe(
          map((enterprises: Enterprise[]) => enterprises[0]),
          tapResponse(
            (enterprise: Enterprise) => {
              this.patchState((state) => ({
                ...state,
                enterprise,
              }));
            },
            () => {
              this.patchState((state) => ({
                ...state,
              }));
            },
          ),
        ),
      ),
    );
  });

  public selectEnterprise(): Observable<UserState['enterprise']> {
    return this.select((state) => state.enterprise);
  }
}
