import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, mergeMap, of, BehaviorSubject, switchMap, filter, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Category } from '../model/category.model';
import { MetaCategory } from '../model/meta-category.model';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  //list of available meta categories
  public metaCategories: MetaCategory[] = [];
  //current meta category (set to first value by default when loaded)
  public currentMeta: MetaCategory | undefined = undefined;
  //list of available categories, by metacategory. 1st level array indexed by meta category id
  private categories: Category[][] = [[]];
  private categoriesAreLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private categoriesAreLoaded: boolean = false;
  private categoriesAreLoading = false;

  constructor(private http: HttpClient) {}

  clear() {
    this.metaCategories = [];
    this.categories = [[]];
    this.categoriesAreLoaded = false;
    this.categoriesAreLoaded$.next(false);
  }

  //loading categories, iddealy should be done once
  loadCategories(): Observable<boolean> {
    return this.http
      .get<{ meta_category: { [key: string]: string }; [key: string]: { [key: string]: string } }>(`${environment.backendUrl}/api/category/`)
      .pipe(
        map((result) => {
          let metaCategories = result.meta_category;

          //
          this.metaCategories = [];

          //loading meta categories
          for (const [key, value] of Object.entries(metaCategories)) {
            this.metaCategories.push(new MetaCategory(parseInt(key), value));
          }

          //setting default current meta
          this.currentMeta = this.metaCategories[0];
          //building categories sets
          this.metaCategories
            .map((meta) => meta.id)
            .filter((x) => x !== undefined)
            .forEach((metaId: number | undefined) => {
              if (metaId !== undefined && !isNaN(metaId) && result.hasOwnProperty(metaId + '')) {
                //we found an object describing a set of categories
                if (typeof result[metaId] === 'object') {
                  let categories: Category[] = [];
                  //pushing it into a list of Category objects
                  for (const [key, value] of Object.entries(result[metaId])) {
                    categories.push(new Category(parseInt(key), value));
                  }
                  this.categories[metaId] = categories;
                }
              }
            });

          return true;
        }),
      );
  }

  /**
   * sets current meta category by its id
   * @param metaId
   */
  setCurrentMeta(metaId: number) {
    this.currentMeta = this.metaCategories.find((mc) => {
      return mc.id + '' === metaId + '';
    });
  }

  //return available categories for crrent meta, loads categories & meta if needed.
  getCurrentMeta(): Observable<MetaCategory> {
    // all work is done, just need to return stuff
    if (this.categoriesAreLoaded && this.currentMeta) {
      return of(this.currentMeta);
    }
    //nothing is done, need to load data
    else if (!this.categoriesAreLoading) {
      this.categoriesAreLoading = true; //tell we are loading
      this.loadCategories().subscribe((success) => {
        if (success && this.currentMeta) {
          this.categoriesAreLoading = false;
          this.categoriesAreLoaded = true;
          this.categoriesAreLoaded$.next(true);
        } else {
          this.categoriesAreLoading = false;
          throw new Error('no categories');
        }
      });
    }

    return this.categoriesAreLoaded$.pipe(
      filter((loaded) => loaded === true), //till its not loaded
      take(1), //ignore data
      map((result) => {
        //here we know stuff is loaded
        if (this.currentMeta) {
          return this.currentMeta;
        } else {
          throw new Error('No current meta category');
        }
      }),
    );
  }

  getCategoryById(id: number): Category | undefined {
    if (this.currentMeta) {
      return this.categories[this.currentMeta?.id].find((cat) => {
        return cat.id + '' === id + '';
      });
    }
    return undefined;
  }

  //return available categories for crrent meta, loads categories & meta if needed.
  getCategories(): Observable<Category[]> {
    if (this.categoriesAreLoaded && this.currentMeta?.id) {
      return of(this.categories[this.currentMeta?.id]);
    } else if (!this.categoriesAreLoading) {
      this.categoriesAreLoading = true;
      this.loadCategories().subscribe((success) => {
        if (success && this.currentMeta?.id) {
          this.categoriesAreLoading = false;
          this.categoriesAreLoaded = true;
          this.categoriesAreLoaded$.next(true);
        } else {
          this.categoriesAreLoading = false;
        }
      });
    }

    return this.categoriesAreLoaded$.pipe(
      filter((loaded) => loaded === true), //till its not loaded
      take(1),
      map((result) => {
        if (this.currentMeta) {
          return this.categories[this.currentMeta.id];
        } else {
          return this.categories[0];
        }
      }),
    );
  }
}
