import { Category } from './category.model';

export class DicoEntry {
  constructor(
    public category: Category,
    public realNMinusOne: number,
    public real: number,
    public forecast: number,
    public evol: number[],
    public curveNMinusOne: number[],
    public stock?: number,
    public dispo?: number,
  ) {}
}
