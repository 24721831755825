import { HttpClient, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Product } from '../component/pages/dashboard/product.store';
import { Email, Generate, Progress, StripoEmail, Template } from '../component/pages/generator/email.store';
import { MetaCategory } from '../model/meta-category.model';

type Images = { [key: string]: string };
interface GifResponse {
  task_id: string;
}

export interface GifStatus {
  status: string;
  progress: number;
  gif_url: string;
}

@Injectable({ providedIn: 'root' })
export class GeneratorService {
  constructor(private httpClient: HttpClient) {}

  public getTemplates(): Observable<Template[]> {
    return this.httpClient.get<Template[]>(`${environment.backendUrl}/api/template`);
  }


  public generateEmail(
    metaCategoryId: MetaCategory['id'],
    ids: Product['id'][],
    images_products_urls: Product['image_url_1'][],
    theme: string,
    templateId: Template['id'],
    visuel_url?:string,
    task_id?:string,
    selected_ratio?: number
  ): Observable<Generate> {
    const payload: any = {
        meta: metaCategoryId,
        ids,
        images_products_urls,
        theme,
        id_template: templateId
    };

    if (visuel_url) {
        payload.visuel_url = visuel_url;
    }

    if (task_id) {
        payload.task_id = task_id;
    }
    if (selected_ratio) {
      payload.selected_ratio = selected_ratio;
  }

    return this.httpClient.post<Generate>(`${environment.backendUrl}/api/generate/`, payload);
}
  
  public generateEmailFinal(
    task_id: Generate['task_id'],
    images: Images,
  ): Observable<Generate> {
    return this.httpClient.post<Generate>(`${environment.backendUrl}/api/generate_final/`, {
      task_id,
      images,
    });
  }

  public progress(id: Generate['task_id']): Observable<Progress> {
    return this.httpClient.get<Progress>(`${environment.backendUrl}/api/progress/${id}`);
  }

  public getEmails(): Observable<Email[]> {
    return this.httpClient.get<Email[]>(`${environment.backendUrl}/api/generate`);
  }

  public getEmail(id: Email['id']): Observable<StripoEmail> {
    return this.httpClient.get<StripoEmail>(`${environment.backendUrl}/api/generate`, {
      params: {
        id_generated: `${id}`,
      },
    });
  }

  public saveEmail(id: Email['id'] | null, html: string, css: string): Observable<string> {
    return this.httpClient.post<string>(`${environment.backendUrl}/api/generatesave/`, {
      id,
      html,
      css,
    });
  }
  public getLangagecode(get_language_code: number | null): Observable<string> {
    return this.httpClient.post<string>(`${environment.backendUrl}/api/generatesave/`, {
      get_language_code
    });
  }
  public translateEmail(id: Email['id'], langage_code: string): Observable<Email> {
    return this.httpClient.post<Email>(`${environment.backendUrl}/api/generatesave/`, {
      id,
      langage_code,
    });
  }
  public copyEmail(id: Email['id'], html: string, css: string): Observable<Email> {
    return this.httpClient.post<Email>(`${environment.backendUrl}/api/generatesave/`, {
      id,
      html,
      css,
      copy: 1  // Indiquer que c'est une copie
    });
  }

  public resizeImage(url: string, ratio: number): Observable<{ [key: string]: string }> {
    // Créer les paramètres de requête
    let params = new HttpParams()
      .set('url', url)
      .set('ratio', ratio.toString());
  
    // Envoyer la requête avec les paramètres
    // Assurez-vous que le type de retour correspond à la nouvelle structure de réponse
    return this.httpClient.post<{ [key: string]: string }>(`${environment.backendUrl}/api/image_resize/`, null, {
      params: params,
    });
  }

  public majRemiseProduct(image_url: string, html_url: string): Observable<{ [key: string]: string }> {
    // Créer le corps de la requête avec les données à envoyer
    const body = {
      url_html: html_url,
      url_image: image_url
    };
  
    // Envoyer la requête POST avec le corps en JSON
    return this.httpClient.post<{ [key: string]: string }>(`${environment.backendUrl}/api/maj_remise_produit/`, body);
  }
  

  public imgageToGif(url: string): Observable<GifResponse> {
    // Envoyer les paramètres dans le corps de la requête POST
    const body = { image_url: url };
    return this.httpClient.post<GifResponse>(`${environment.backendUrl}/api/create_gif_from_static_image/`, body);
  }

  public imgageToGifProgress(task_id: string): Observable<GifStatus> {
    return this.httpClient.get<GifStatus>(`${environment.backendUrl}/api/create_gif_from_static_image/${task_id}/`);
  }
  

  public writeOnimage(url: string, text_up: string, text_down: string, single_text: string): Observable<{[key: string]: string}> {
    // Créer les paramètres de requête
    let params = new HttpParams()
      .set('url', url)
      .set('text_up', text_up)
      .set('text_down', text_down)
      .set('single_text', single_text);

    // Envoyer la requête avec les paramètres
    return this.httpClient.post<{[key: string]: string}>(`${environment.backendUrl}/api/write_on_image/`, null, {
      params: params,
    });
  }

  public deleteEmail(id: Email['id']): Observable<void> {
    return this.httpClient.get<void>(`${environment.backendUrl}/api/generate`, {
      params: {
        del_email: `${id}`,
      },
    });
  }

  public regenerateImage(taskId: string, imageUrl: string, content: string, task_id_midjourney : string): Observable<any> {
    return this.httpClient.post<any>(`${environment.backendUrl}/api/image_selection/`, {
      reload: true,
      task_id: taskId,
      url_discord: imageUrl,
      content: content,
      task_id_midjourney :task_id_midjourney
    });
  }
  
  public selectImage(imageNumber: number, imageUrl: string): Observable<any> {
    return this.httpClient.post<any>(`${environment.backendUrl}/api/image_selection/`, {
      reload: false,
      img_number: imageNumber,
      url_discord: imageUrl,
    });
  }

  public uploadFile(file: File): Observable<string> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    
    // Supposons que vous ayez un endpoint séparé pour l'upload d'images
    return this.httpClient.post<{ url: string }>(`${environment.backendUrl}/api/image_tmp/upload/`, formData).pipe(
        map(response => response.url)
    );
  }
  
  public exportSocial(imageUrl: string, htmlUrl: string): Observable<Blob> {
    return this.httpClient.post(`${environment.backendUrl}/api/export_social/?url_html_image=${htmlUrl}&url_preview_image=${imageUrl}`, null, {
      responseType: 'blob'
    });
  }

  public resizeImagesInHtml(html: string): Observable<{ html: string }> {
    return this.httpClient.post<{ html: string }>(`${environment.backendUrl}/api/resize_images_in_html/`, { html: html });
  }

  public createGifProduct(selected_images: string[], ratio_produit: number): Observable<any> {
    return this.httpClient.post<any>(`${environment.backendUrl}/api/create_gif_product/`, {
      selected_images: selected_images,
      ratio_produit: ratio_produit
    });
  }

  public scrapImageProductPage(url_image: string, url_html: string): Observable<any> {
    return this.httpClient.post<any>(`${environment.backendUrl}/api/scrap_image_product_page/`, {
      url_image: url_image,
      url_html: url_html
    });
  }

  public addTrackingToUrls(html: string, tracking: string): Observable<{ html: string }> {
    return this.httpClient.post<{ html: string }>(`${environment.backendUrl}/api/add_tracking_to_urls/`, {
      html: html,
      tracking: tracking
    });
  }

  public pushEmail(email_id: number, html: string): Observable<{ status: string; message: string }> {
    return this.httpClient.post<{ status: string; message: string }>(`${environment.backendUrl}/api/push_email/`, {
      email_id: email_id,
      html_compiled: html
    });
  }
  

  public changeValueInEnterprise(id_entreprise: number, field: string, value: string): Observable<any> {
    return this.httpClient.patch<any>(`${environment.backendUrl}/api/enterprise/${id_entreprise}/`, {
      [field]: value
    });
  }

}
