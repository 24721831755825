import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, map, mergeMap, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Category } from '../model/category.model';
import { DicoEntry } from '../model/dico-entry.model';
import { CategoryService } from './category.service';

@Injectable({
  providedIn: 'root',
})
export class DicoService {
  constructor(private http: HttpClient, private categoryService: CategoryService) {}

  findCategoryById(categories: Category[], id: number): Category | undefined {
    return categories.find(category => category.id === id);
}

  getDico(numDays: number, category?: Category): Observable<DicoEntry[]> {
    return combineLatest([this.categoryService.getCategories(), this.categoryService.getCurrentMeta()]).pipe(
      mergeMap((result) => {
        let [categories, meta] = result;

        let params: { [key: string]: any } = {};
        if (numDays) {
          params['day'] = numDays;
        }
        if (category && category.id) {
          params['category'] = category.id;
        } else {
          params['category'] = categories.map((cat) => cat.id).join(',');
        }
        params['meta_category'] = meta.id;

        //requesting dico infos
        return this.http
          .get<{
            [key: number]: {
              real_n_1: number;
              real: number;
              forecast: number;
              curve_n_1: number[];
              curve_forecast: number[];
            };
          }>(`${environment.backendUrl}/api/dico/`, { params })
          .pipe(
            map(
              (response: {
                [key: number]: {
                  real_n_1: number;
                  real: number;
                  forecast: number;
                  curve_n_1: number[];
                  curve_forecast: number[];
                };
              }) => {
                let units: DicoEntry[] = [];

                for (const [id, entry] of Object.entries(response)) {
                  const category = this.findCategoryById(categories, parseInt(id));
                  if (category) {
                    let dicoEntry = new DicoEntry(
                      new Category(parseInt(id), category.label),
                      entry.real_n_1,
                      entry.real,
                      entry.forecast,
                      entry.curve_forecast,
                      entry.curve_n_1,
                    );
                    units.push(dicoEntry);
                }
                }
                return units;
              },
            ),
          );
      }),
    );
  }

  getRatio(entry: DicoEntry | null, lastYear = false): number {
    if (entry) {
      if (lastYear) {
        return ((entry.forecast - entry.realNMinusOne) / (entry.realNMinusOne)) * 100;
      }
      return ((entry.forecast - entry.real) / (entry.real)) * 100;
    }
    return 0;
  }
}
