<div *ngIf="show" [@slideInOut]="panelOuvert ? 'ouvert' : 'ferme'" class="side-panel">
  <button nbButton status="info" class="open-panel-button" [style.left]="panelOuvert ? '-65px' : '-110px'" (click)="togglePanel()">
    {{ panelOuvert ? 'Réduire' : 'Voir la disposition' }}
  </button>

  <!-- Utilisation de Gridster -->
  <gridster [options]="options">
    <gridster-item *ngFor="let item of dashboard; trackBy: trackByItem" [item]="item">
      <div class="item-container">
        <!-- Image Slider -->
        <div *ngIf="getImageList(item) as imageList" class="image-slider">
          <!-- Vérifiez si imageList a des images -->
          <img *ngIf="imageList.length > 0" [src]="getCurrentImage(item)" class="slider-image" (load)="adjustImageSizeOnLoad($event)" />
        
          <!-- Afficher les flèches seulement s'il y a plus d'une image -->
          <button *ngIf="imageList.length > 1" class="slider-arrow left-arrow" (click)="previousImage(item)">
            &#10094;
          </button>
          <button *ngIf="imageList.length > 1" class="slider-arrow right-arrow" (click)="nextImage(item)">
            &#10095;
          </button>
        
          <div class="slider-dots-container" *ngIf="imageList.length > 0">
            <div class="slider-dots">
              <!-- Utilisez imageList dans la boucle *ngFor -->
              <span *ngFor="let image of imageList; let i = index"
                    class="dot"
                    [class.active]="i === (currentImageIndexes[item.id!] || 0)"
                    (click)="selectImage(item, i)"></span>
              <span [nbSpinner]="loadingImages[item.id!]" nbSpinnerSize="tiny" nbSpinnerStatus="control" class="dot-spinner"></span>
            </div>
          </div>
        </div>
        
  
        <!-- Controls -->
        <i *ngIf="item && item.id && !isGifImage(getCurrentImage(item))" class="bi bi-filetype-gif gif-image" (click)="createGif($event, item)" nbTooltip="Créer un GIF avec vos images" nbTooltipStatus="warning"></i>
        <nb-icon *ngIf="item && item.id" class="upload-image" icon="camera-outline" (click)="onButtonClick($event, item)" nbTooltip="Remplacer cette image avec une image de votre ordinateur." nbTooltipStatus="info"></nb-icon>
        <nb-icon *ngIf="item && item.id" class="remove-button" icon="trash-2-outline" (click)="removeProduct(item)" nbTooltip="Désélectionner ce produit" nbTooltipStatus="danger"></nb-icon>
        <input id="file-upload-drag-and-drop" 
       type="file" 
       style="display:none;" 
       (click)="setClickedProduct(item)"
       (change)="onFileSelected($event)">
      </div>
    </gridster-item>
  </gridster>
</div>
<app-create-gif-product
[show]="showCreateGif"
(closeRequest)="handleCloseRequest()"
(gifCreated)="handleGifCreated($event)">
</app-create-gif-product>

<ng-template #dialogUploadImage let-data let-ref="dialogRef">
  <nb-card>
    <nb-card-body class="text-center"> {{ this.errorMessage }} </nb-card-body>
    <nb-card-footer>
      <div class="row">
        <div class="col text-center">
          <button nbButton status="danger" (click)="ref.close()">OK</button>
        </div>
      </div>
    </nb-card-footer>
  </nb-card>
</ng-template>