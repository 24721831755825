<div class="modal-fullscreen" *ngIf="show">
    <div class="modal-header">
        <button type="button" class="btn btn-outline-secondary" (click)="onClose()">Retour</button>
    </div>

    <div class="modal-container">
      <div class="modal-content" (click)="$event.stopPropagation()">
        <p *ngIf="selectedImages.length > 0">{{ selectedMessage }}</p>

          <div *ngIf="selectedImages.length > 0"
              class="container"
              cdkDropList 
              (cdkDropListDropped)="drop($event)"
              cdkDropListOrientation="horizontal">
            <div *ngFor="let selectedImage of selectedImages; let i = index" cdkDrag class="item selected-image-wrapper">
                <div class="delete-button" (click)="removeImage(i)">
                  <i class="bi-trash"></i>
                </div>
                <img [src]="selectedImage" alt="Image" class="selected-image" />
            </div>
            <button type="button" class="btn btn-primary create-gif-button" (click)="startCreateGif()" [disabled]="!isButtonEnabled || gifUrl !== ''">
              Créer le GIF
            </button>
            <div class="images-container">
              <div class="gif-container">
                <ng-container *ngIf="gifUrl === 'loading'; else showGif">
                  <div class="loading-spinner-gif">
                    <div class="spinner"></div>
                  </div>
                </ng-container>
                <ng-template #showGif>
                  <div class="delete-button-gif" (click)="removeGif()" *ngIf="gifUrl !== 'loading' && gifUrl !== ''">
                    <i class="bi-trash"></i>
                  </div>
                  <img [src]="gifUrl" class="displayed-image-gif">
                </ng-template>
              </div>
            </div>
            <button type="button"
              *ngIf="this.gifUrl !== 'loading' && this.gifUrl !== ''"
              class="btn btn-primary validate-gif-button"
              (click)="validateGif()"
              [disabled]="this.gifUrl === 'loading' || this.gifUrl === ''">
              Valider le GIF
            </button>
          </div>
          
          <p class="largeur-50"> {{ scrappedMessage }}</p>
          
        <div class="images-container">
          <label for="file-upload" class="custom-file-upload" 
              [class.dragover]="isDragging"
              (dragover)="onDragOver($event)" 
              (dragenter)="onDragEnter($event)"
              (dragleave)="onDragLeave($event)"
              (drop)="onDrop($event)">
              <button nbButton appearance="filled" status="danger" (click)="onButtonClick($event)">
                  Charger une image
              </button>
              <p><br>Ou faire glisser un fichier image</p>
              <p *ngIf="errorMessage" class="error-message">{{ errorMessage }}</p>
              <i class="fa fa-plus"></i>
          </label>
          <input id="file-upload-create-gif" type="file" style="display:none;"
              (change)="onFileSelected($event); uploadSelectedFile()">
              <ng-container *ngFor="let imageUrl of imageUrls">
                <div class="image-container" [class.selected]="selectedImages.includes(imageUrl)" *ngIf="imageUrl !== '0'" (click)="onImageClick($event, imageUrl)">
                  <ng-container *ngIf="imageUrl === 'loading'; else showImage">
                    <div class="loading-spinner">
                      <div class="spinner"></div>
                    </div>
                  </ng-container>
                  <ng-template #showImage>
                    <div class="image-wrapper">
                      <img [src]="imageUrl" class="displayed-image"/>
                      <ng-container *ngIf="this.loading === true">
                        <div class="loading-spinner-scrapped">
                          <div class="spinner-scrapped"></div>
                          <div class="empty-border"></div>
                        </div>
                      </ng-container>
                    </div>
                    <div *ngIf="selectedImages.includes(imageUrl)" class="selection-number">
                      {{ getSelectionIndex(imageUrl) }}
                    </div>
                  </ng-template>
                </div>
              </ng-container>
          <!-- Upload button styled as an image with a "+" icon -->
        </div>
    </div>
</div>

<ng-template #dialogGifTemplate let-ref="dialogRef">
  <nb-card>
    <nb-card-body>{{ errorMessageGif }}</nb-card-body>
    <nb-card-footer>
      <div class="row">
        <div class="col text-center">
          <button nbButton status="success" (click)="ref.close()">OK</button>
        </div>
      </div>
    </nb-card-footer>
  </nb-card>
</ng-template>

<ng-template #dialogGifTemplate2 let-ref="dialogRef">
  <nb-card>
    <nb-card-body>{{ errorMessageGif }}</nb-card-body>
    <nb-card-footer>
      <div class="row">
        <div class="col text-center">
          <button nbButton status="danger" (click)="ref.close()">OK</button>
        </div>
      </div>
    </nb-card-footer>
  </nb-card>
</ng-template>

<ng-template #dialogErrorGif let-data let-ref="dialogRef">
  <nb-card>
    <nb-card-body class="text-center">Une erreur s'est produite lors de la création de votre GIF. <br>Nous vous invitons à vous rapprocher de votre contact Deebr si l'erreur se reproduit</nb-card-body>
    <nb-card-footer>
      <div class="row">
        <div class="col text-center">
          <button nbButton status="danger" (click)="ref.close()">OK</button>
        </div>
      </div>
    </nb-card-footer>
  </nb-card>
</ng-template>